import moment from 'moment'

import { getAge } from '@/utils/dates'
import { cellPhoneForSms } from '@/utils/phones'

export const GENDER_MALE = 1
export const GENDER_FEMALE = 2
export const GENDER_NOT_SET = 0

export class City {
  constructor(
    public id: number,
    public name: string,
    public zipCodes: string[]
  ) {
  }
}

export class Gender {
  constructor(
    public id: number,
    public name: string,
    public nameForIndividual: string,
    public nameForChild: string
  ) {
  }
}

export class EntityRole {
  constructor(
    public id: number,
    public name: string,
    public familyRole: boolean, // proposed on family form
    public isMain: boolean, // listed in adults or children
    public isChild: boolean, // listed in children
    public isBirtDateRequired: boolean,
    public hasContact: boolean, // show the contact info (phone, email)
    public isLegalRepresentative: boolean, // sélectionnable comme représentant légal
    public gender: number, // default gender : Mother -> Female
    public showChildrenAllowed: boolean,
    public allowComments: boolean,
    public showIndividualLink: boolean,
    public isEmailRecommended: boolean
  ) {
  }
}

export class MinimalEntity {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makeMinimalEntity(jsonData: any = null): MinimalEntity {
  if (jsonData === null) {
    jsonData = {}
  }
  return new MinimalEntity(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class Individual {
  constructor(
    public id: number,
    public lastName: string,
    public firstName: string,
    public birthDate: Date,
    public gender: Gender,
    public createdOn: Date,
    public updatedOn: Date,
    public about: string,
    public cellPhone: string,
    public proPhone: string,
    public email: string,
    public emailContact: boolean,
    public isNewsletter: boolean,
    public childId: number,
    public millibaseNumber: number,
    public entities: MinimalEntity[]
  ) {
  }

  public firstAndLastName(): string {
    const fullName = this.firstName.trim() + ' ' + this.lastName.trim()
    return fullName.trim()
  }

  public lastAndFirstName(): string {
    const fullName = this.lastName.trim() + ' ' + this.firstName.trim()
    return fullName.trim()
  }

  public fullName(): string {
    let prefix
    if (this.gender.id === GENDER_MALE) {
      prefix = 'M. '
    } else if (this.gender.id === GENDER_FEMALE) {
      prefix = 'Mme '
    } else {
      prefix = ''
    }
    return prefix + this.firstAndLastName()
  }

  public isFemale(): boolean {
    return (this.gender.id === GENDER_FEMALE)
  }

  public getAge(): string {
    return getAge(this.birthDate)
  }

  public birthDateAsString(): string {
    return moment(this.birthDate).format('DD/MM/YYYY')
  }

  public getMainPhone(): string {
    return this.cellPhone || this.proPhone
  }

  public cellPhoneForSms(): string {
    return cellPhoneForSms(this.cellPhone)
  }
}

export class Student {
  constructor(
    public id: number,
    public individual: Individual,
    public schoolClassUpdatedOn: Date
  ) {
  }
}

export class EntityMembership {
  constructor(
    public id: number,
    public role: EntityRole,
    public individual: Individual,
    public childrenAllowed: boolean,
    public comments: string,
    public legalRepresentativeId: number = 0
  ) {
  }

  public fullName(): string {
    return this.individual.fullName()
  }
}

export class Entity {
  constructor(
    public id: number,
    public name: string,
    public phone: string,
    public streetNumber: string,
    public street: string,
    public address2: string,
    public zipCode: string,
    public cedex: string,
    public city: City,
    public about: string,
    public createdOn: Date,
    public updatedOn: Date,
    public millibaseNumber: number,
    public familyId: number,
    public memberships: EntityMembership[],
    public members: string,
    public isOwn: boolean // entité correspondant à la structure
  ) {
  }

  public getMainIndividuals(): Individual[] {
    const individuals: Individual[] = []
    for (const membership of this.memberships) {
      if (membership.role.isMain) {
        individuals.push(membership.individual)
      }
    }
    return individuals
  }

  public getParentsIndividuals(): Individual[] {
    const individuals: Individual[] = []
    for (const membership of this.memberships) {
      if (membership.role.isMain && !membership.role.isChild) {
        individuals.push(membership.individual)
      }
    }
    return individuals
  }

  public getChildrenIndividuals(): Individual[] {
    const individuals: Individual[] = []
    for (const membership of this.memberships) {
      if (membership.role.isMain && membership.role.isChild) {
        individuals.push(membership.individual)
      }
    }
    return individuals
  }

  public addressLines(): string[] {
    const lines: string[] = []
    const line1Values = [this.streetNumber, this.street || this.address2]
    lines.push(line1Values.join(' ').trim())
    if (this.street && this.address2 && (this.street !== this.address2)) {
      lines.push(this.address2)
    }
    return lines
  }

  public fullAddress(): string {
    const lines: string[] = this.addressLines()
    lines.push(this.zipCode)
    lines.push(this.city.name)
    return lines.filter(elt => elt).join(' ')
  }
}

export function makeGender(jsonData: any): Gender {
  if (jsonData.id === GENDER_MALE) {
    return new Gender(GENDER_MALE, 'Masculin', 'Monsieur', 'Garçon')
  } else if (jsonData.id === GENDER_FEMALE) {
    return new Gender(GENDER_FEMALE, 'Féminin', 'Madame', 'Fille')
  } else if (jsonData.id === GENDER_NOT_SET) {
    return new Gender(GENDER_NOT_SET, 'Non renseigné', 'Non renseigné', 'Non renseigné')
  } else {
    return new Gender(
      jsonData.id,
      jsonData.name,
      jsonData.nameForIndividual || jsonData.name,
      jsonData.nameForChild || jsonData.name
    )
  }
}

export function makeEntityRole(jsonData: any): EntityRole {
  return new EntityRole(
    jsonData.id || 0,
    jsonData.name || '',
    !!jsonData.family_role,
    !!jsonData.is_main,
    !!jsonData.is_child,
    !!jsonData.is_birth_date_required,
    !!jsonData.has_contact,
    !!jsonData.is_legal_representative,
    jsonData.gender || 0,
    !!jsonData.show_children_allowed,
    !!jsonData.allow_comments,
    !!jsonData.show_individual_link,
    (!!jsonData.is_main) && (!jsonData.is_child)
  )
}

export function makeIndividual(jsonData: any = null, entityId: number = 0): Individual {
  if (jsonData === null) {
    jsonData = {}
  }
  let gender: Gender
  if (jsonData.gender) {
    gender = makeGender({ id: jsonData.gender, name: jsonData.get_gender_display, })
  } else {
    gender = makeGender({ id: 0, name: '', })
  }
  let entities = []
  if (entityId) {
    entities = [makeMinimalEntity({ id: entityId, })]
  }
  if (jsonData.entities) {
    entities = jsonData.entities.map(
      (jsonElt: any) => makeMinimalEntity(jsonElt)
    )
  }
  return new Individual(
    jsonData.id || 0,
    jsonData.last_name || '',
    jsonData.first_name || '',
    jsonData.birth_date || null,
    gender,
    jsonData.created_on || null,
    jsonData.updated_on || null,
    jsonData.about || '',
    jsonData.cell_phone || '',
    jsonData.pro_phone || '',
    jsonData.email || '',
    !!jsonData.email_contact,
    !!jsonData.is_newsletter,
    0,
    jsonData.millibase_number || 0,
    entities
  )
}

export function makeStudent(jsonData: any = null): Student {
  if (jsonData === null) {
    jsonData = {}
  }
  return new Student(
    jsonData.id || 0,
    makeIndividual(jsonData.individual || {}),
    jsonData.school_class_updated_on || ''
  )
}

export function makeEntityMembership(jsonData: any = null, entityId: number = 0): EntityMembership {
  if (jsonData === null) {
    jsonData = {}
  }
  return new EntityMembership(
    jsonData.id || 0,
    makeEntityRole(jsonData.role || {}),
    makeIndividual(jsonData.individual || {}, entityId),
    jsonData.children_allowed || false,
    jsonData.comments || ''
  )
}

export function makeCity(jsonData: any): City {
  if (jsonData === null) {
    jsonData = {}
  }
  return new City(jsonData.id || 0, jsonData.name || '', jsonData.zip_codes || [])
}

export function makeEntity(jsonData: any = null): Entity {
  if (jsonData === null) {
    jsonData = {}
  }
  let city: City|null
  if (jsonData.city) {
    city = makeCity(jsonData.city)
  } else {
    city = makeCity({ id: 0, name: '', })
  }
  const memberships = jsonData.memberships || []

  return new Entity(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.phone || '',
    jsonData.street_number || '',
    jsonData.street || '',
    jsonData.address2 || '',
    jsonData.zip_code || '',
    jsonData.cedex || '',
    city,
    jsonData.about || '',
    jsonData.created_on || null,
    jsonData.updated_on || null,
    jsonData.millibase_number || 0,
    jsonData.family || 0,
    memberships.map((elt: any) => makeEntityMembership(elt)),
    jsonData.members || '',
    !!jsonData.is_own
  )
}

export class Tag {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makeTag(jsonData: any): Tag {
  if (jsonData === null) {
    jsonData = {}
  }
  return new Tag(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class TagMembership extends Tag {
  constructor(
    public id: number,
    public name: string,
    public comments: string
  ) {
    super(id, name)
  }
}

export function makeTagMembership(jsonData: any): TagMembership {
  if (jsonData === null) {
    jsonData = {}
  }
  return new TagMembership(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.comments || ''
  )
}

export class IndividualEntityMembership {
  constructor(
    public id: number,
    public entity: MinimalEntity,
    public role: EntityRole
  ) {
  }

  public asText(): string {
    if (this.role.familyRole) {
      return 'Famille ' + this.entity.name + ' - ' + this.role.name
    } else {
      return this.entity.name + ' - ' + this.role.name
    }
  }
}

export function makeIndividualEntityMembership(jsonData: any = null): IndividualEntityMembership {
  if (jsonData === null) {
    jsonData = {}
  }
  return new IndividualEntityMembership(
    jsonData.id || 0,
    makeMinimalEntity(jsonData.entity),
    makeEntityRole(jsonData.role)
  )
}
