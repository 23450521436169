import { encrypt, decrypt } from '@/utils/encrypt'
import { User } from '@/types/auth'

export function storeUser(user: User): void {
  const userAsString: string = JSON.stringify(user)
  window.localStorage.setItem('user', encrypt(userAsString))
}

export function loadUser(): User {
  const encryptedValue = window.localStorage.getItem('user')
  if (encryptedValue) {
    let value = ''
    try {
      value = decrypt(encryptedValue)
    } catch (exc1) {
      value = ''
    }
    if (value) {
      try {
        const user: User = JSON.parse(value)
        if (user.token.length > 0) {
          return user
        }
      } catch (exc2) {
        // nothing
      }
    }
  }
  // anonymous User
  return new User()
}
