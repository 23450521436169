
export function calcHeaderStyle(items: any[]): any {
  const style: any = {}
  for (const obj of items) {
    if (obj.backgroundColor) {
      style.backgroundColor = obj.backgroundColor
      if (obj.textColor) {
        style.color = obj.textColor + ' !important'
      }
      return style
    }
  }
  return style
}
