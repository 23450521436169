import { Entity } from '@/types/people'

export const entityModule = {
  state: {
    entities: [],
    selectedEntity: null,
  },
  getters: {
    entities: (state: any) => {
      return state.entities
    },
    selectedEntity: (state: any) => {
      return state.selectedEntity
    },
  },
  mutations: {
    setEntities(state: any, entities: Entity[]) {
      state.entities = entities
    },
    setSelectedEntity(state: any, selectedEntity: Entity[]) {
      state.selectedEntity = selectedEntity
    },
  },
  modules: {
  },
}

export default entityModule
