import moment from 'moment'

export function dateToString(date: Date, format: string = 'L'): string {
  if (!date) {
    return '-'
  } else {
    return moment(date).format(format)
  }
}

export function defaultValue(value: any, defaultValue = '-'): any {
  if (!value) {
    return defaultValue
  } else {
    return value
  }
}

export function age(value: any): string {
  if (value) {
    return '' + moment().diff(moment(value), 'years') + ' ans'
  }
  return ''
}

export function yesno(value: any): string {
  if (value) {
    return 'Oui'
  }
  return 'Non'
}

export function currency(value: any): any {
  if (isNaN(value) || (value === null)) {
    return '---'
  }
  const roundedValue = Math.round(100 * value) / 100
  return '' + roundedValue + '€'
}

export function hour(value: string): string {
  const slices = value.split(':')
  if (slices.length > 2) {
    slices.pop()
    return slices.join(':')
  }
  return value
}

export function round(value: any, digits: number = 2): string {
  // 11.00 --> 11
  // 11.50 --> 11.5
  // 11.999 --> 12
  if (isNaN(value) || (value === null)) {
    return '-'
  }
  for (let index = digits; index > 0; index--) {
    const multiplier = Math.pow(10, digits)
    const roundedValue = Math.round(multiplier * value) / multiplier
    const asText = '' + roundedValue
    if ((asText.indexOf('.') < 0) || (asText[asText.length - 1] !== '0')) {
      return asText
    }
  }
  return '' + value
}

export function fileSize(value: number): string {
  const multipliers = ['o', 'Ko', 'Mo', 'Go']

  let scaledValue = value
  let multiplierIndex = 0
  while (scaledValue >= 1024 && multiplierIndex < multipliers.length) {
    scaledValue = scaledValue / 1024
    multiplierIndex += 1
  }
  const roundedValue = Math.round(scaledValue * 100) / 100
  const multiplier = multipliers[multiplierIndex]
  return roundedValue + multiplier
}

export function tsToDate(value: number): string {
  return moment(new Date(value)).format('DD/MM/YYYY HH:mm:ss')
}
