import { CertificateRequest } from '@/types/certificates'

export const certificatesModule = {
  state: {
    certificateRequests: [],
  },
  getters: {
    certificateRequests: (state: any) => {
      return state.certificateRequests
    },
  },
  mutations: {
    setCertificateRequests(state: any, certificateRequests: CertificateRequest[]) {
      state.certificateRequests = certificateRequests
    },
  },
  modules: {
  },
}

export default certificatesModule
