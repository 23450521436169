export function distinct(objects: any[], field: string = 'id'): any[] {
  const ids = objects.map(elt => elt[field])
  return objects.filter((elt, index) => ids.indexOf(elt[field]) === index)
}

export function distinctElements(elements: any[]): any[] {
  return elements.filter((elt, index) => elements.indexOf(elt) === index)
}

export function distinctString(objects: string[]): string[] {
  const strings = objects.concat([])
  return objects.filter((elt, index) => strings.indexOf(elt) === index)
}

export function existsIn(list1: any[], list2: any[]): boolean {
  for (const value of list1) {
    if (list2.indexOf(value) >= 0) {
      return true
    }
  }
  return false
}

export function areSameArrays(list1: any[], list2: any[], reorder: any = null): boolean {
  if (list1.length === list2.length) {
    let array1 = list1
    let array2 = list2
    if (reorder) {
      array1 = array1.sort(reorder)
      array2 = array2.sort(reorder)
    }
    for (let index = 0; index < array1.length; index++) {
      if (array1[index] !== array2[index]) {
        return false
      }
    }
    return true
  }
  return false
}
