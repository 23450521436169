import { Child, makeChild } from './families'
import {
  Discount, Invoice, makeDiscount, makeInvoice, Sale, calculateSoldPrice, SaleWithInvoice, makeSaleWithInvoice,
  AnalyticAccount, makeAnalyticAccount, GeneralAccount, makeGeneralAccount
} from './payments'
import { Individual, makeIndividual, Entity, makeEntity } from './people'
import { SchoolYear, makeSchoolYear } from './schools'
import { StaffUser, makeStaffUser } from './users'
import { distinctString } from '@/utils/arrays'
import { calcHeaderStyle } from '@/utils/style'

export enum ActivityPricingRule {
  Grid = 1,
  Flat = 2,
  Percentage = 3,
  Free = 4
}

export function getActivityPricingRuleName(value: ActivityPricingRule, hasFlatPrices: boolean = false): string {
  switch (value) {
    case ActivityPricingRule.Flat:
      return hasFlatPrices ? 'Prix fixes' : 'Gratuit'
    case ActivityPricingRule.Grid:
      return 'Prix selon grille de QF'
    case ActivityPricingRule.Percentage:
      return 'Prix selon pourcentage de QF'
    case ActivityPricingRule.Free:
      return 'Prix libre'
  }
  return ''
}

export enum ActivityCategory {
  None = 0,
  Simple = 1,
  Event = 2,
  Workshop = 3,
  YouthHome = 4,
  Multi = 5,
  AdultsYouthHome = 6,
}

export function getActivityCategoryName(value: ActivityCategory): string {
  switch (value) {
    case ActivityCategory.None:
      return 'Aucune'
    case ActivityCategory.Simple:
      return 'Activité'
    case ActivityCategory.Event:
      return 'Événement'
    case ActivityCategory.Workshop:
      return 'Séance'
    case ActivityCategory.YouthHome:
      return 'Accueil de loisirs'
    case ActivityCategory.AdultsYouthHome:
      return 'Accueil adultes'
    case ActivityCategory.Multi:
      return 'Multi-accueil'
  }
  return ''
}

export class ActivityPrice {
  constructor(
    public index: number,
    public name: string,
    public price: number,
    public warning: string = ''
  ) {
  }
}

export class ActivityGridPrice {
  constructor(
    public id: number,
    public name: string,
    public slices: ActivityPrice[]
  ) {
  }
}

export class ActivityAnalyticAccount {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export class ActivityType {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}
export class InscriptionSeat {
  constructor(
    public id: number,
    public index: number,
    public name: string,
    public price: number,
    public seats: number
  ) {
  }
}

export function makeInscriptionSeat(jsonData: any = null): InscriptionSeat {
  if (!jsonData) {
    jsonData = {}
  }
  return new InscriptionSeat(
    jsonData.id || 0,
    jsonData.index || 0,
    jsonData.name || '',
    jsonData.price || 0,
    jsonData.seats || 0
  )
}
export function makeActivityType(jsonData: any = null): ActivityType {
  if (!jsonData) {
    jsonData = {}
  }
  return new ActivityType(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class Activity {
  constructor(
    public id: number,
    public schoolYear: SchoolYear,
    public name: string,
    public disabled: boolean,
    public pricingRule: number,
    public flatPrices: ActivityPrice[],
    public familyLevelPrice: number,
    public priceSlices: ActivityPrice[],
    public startTime: string,
    public endTime: string,
    public startDate: Date,
    public day: string,
    public duration: number,
    public place: string,
    public about: string,
    public conditions: string,
    public seancesCount: number,
    public minimumParticipants: number,
    public maximumParticipants: number,
    public analyticAccount: AnalyticAccount,
    public generalAccount: GeneralAccount,
    public analyticAccounts: ActivityAnalyticAccount[],
    public minPrice: number,
    public maxPrice: number,
    public familyLevelRatio: number,
    public gridPrices: ActivityGridPrice[],
    public inscriptionsCount: number,
    public waitingCount: number,
    public tryingCount: number,
    public scale: number,
    public inheritFrom: Activity|null,
    public heirs: Activity[],
    public keywords: string,
    public category: ActivityCategory,
    public seatsCount: number,
    public waitingSeatsCount: number,
    public backgroundColor: string,
    public textColor: string,
    public activityType: ActivityType
  ) {
  }

  public getPrices(): ActivityPrice[] {
    if (this.pricingRule === ActivityPricingRule.Flat) {
      return this.flatPrices
    } else if (this.pricingRule === ActivityPricingRule.Percentage) {
      return [new ActivityPrice(0, '', this.familyLevelPrice)]
    } else if (this.pricingRule === ActivityPricingRule.Grid) {
      return this.priceSlices
    }
    return []
  }

  public isFreePrice(): boolean {
    return (this.pricingRule === ActivityPricingRule.Free)
  }

  public fullName(): string {
    return this.name + ' ' + this.schoolYear.name
  }

  public nameForInscription(): string {
    return this.name + (this.disabled ? ' (suspendue)' : '')
  }

  public getKeywords(): string[] {
    const keywords = this.keywords.trim().split(' ').map(
      elt => elt.trim()
    ).filter(elt => elt)
    return distinctString(keywords)
  }

  public getCategoryName(): string {
    return getActivityCategoryName(this.category)
  }

  public isEvent(): boolean {
    return this.category === ActivityCategory.Event
  }

  public allowAdults(): boolean {
    return this.category !== ActivityCategory.YouthHome
  }

  public getHeaderStyle() {
    const style: any = {}
    if (!this.disabled) {
      return calcHeaderStyle([this])
    }
    return style
  }
}

export function makeActivityAnalyticAccount(jsonData: any = null): ActivityAnalyticAccount {
  if (!jsonData) {
    jsonData = {}
  }
  return new ActivityAnalyticAccount(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export function makeActivityPrice(index: number, jsonData: any = null): ActivityPrice {
  if (!jsonData) {
    jsonData = {}
  }
  return new ActivityPrice(
    index,
    jsonData.name || '',
    jsonData.price || 0,
    jsonData.warning || ''
  )
}

export function makeActivityGridPrice(jsonData: any = null): ActivityGridPrice {
  if (!jsonData) {
    jsonData = {}
  }
  const slices = jsonData.slices || []
  return new ActivityGridPrice(
    jsonData.id || 0,
    jsonData.name || '',
    slices.map((elt: any) => elt.price)
  )
}

export function makeActivity(jsonData: any = null): Activity {
  if (!jsonData) {
    jsonData = {}
  }
  const flatPrices = jsonData.flat_prices || []
  const priceSlices = jsonData.price_slices || []
  const gridPrices = jsonData.grid_prices || []
  const analyticAccounts = jsonData.activity_analytic_accounts || []
  const heirs = jsonData.heirs || []
  let technicalCategory: ActivityCategory = jsonData.technical_category || ActivityCategory.None
  if ((technicalCategory === ActivityCategory.None) || (technicalCategory === ActivityCategory.Workshop)) {
    technicalCategory = ActivityCategory.Simple
  }
  return new Activity(
    jsonData.id || 0,
    makeSchoolYear(jsonData.school_year),
    jsonData.name || '',
    !!jsonData.disabled,
    jsonData.pricing_rule || 0,
    flatPrices.map((elt: any, index: number) => { return makeActivityPrice(index, elt) }),
    jsonData.price_by_family_level || 0,
    priceSlices.map((elt: any, index: number) => { return makeActivityPrice(index, elt) }),
    jsonData.start_time || '',
    jsonData.end_time || '',
    jsonData.start_date || null,
    jsonData.day || '',
    +jsonData.duration || 0,
    jsonData.place || '',
    jsonData.about || '',
    jsonData.conditions || '',
    jsonData.seances_count || 0,
    jsonData.minimum_participants || 0,
    jsonData.maximum_participants || 0,
    makeAnalyticAccount(jsonData.analytic_account),
    makeGeneralAccount(jsonData.general_account),
    analyticAccounts.map((elt: any) => makeActivityAnalyticAccount(elt)),
    jsonData.min_price === null ? null : jsonData.min_price,
    jsonData.max_price === null ? null : jsonData.max_price,
    jsonData.family_level_ratio === null ? null : jsonData.family_level_ratio,
    gridPrices.map((elt: any) => makeActivityGridPrice(elt)),
    jsonData.inscriptions_count || 0,
    jsonData.waiting_count || 0,
    jsonData.trying_count || 0,
    jsonData.scale || 0,
    jsonData.inherit_from ? makeActivity(jsonData.inherit_from) : null,
    heirs.map((elt: any) => makeActivity(elt)),
    jsonData.keywords || '',
    technicalCategory,
    jsonData.seats_count || 0,
    jsonData.waiting_seats_count || 0,
    jsonData.background_color || '',
    jsonData.text_color || '',
    makeActivityType(jsonData.activity_type)
  )
}

export class ActivityInscription {
  constructor(
    public id: number,
    public individual: Individual,
    public entity: Entity,
    public activity: Activity,
    public price: number,
    public createdOn: Date,
    public createdBy: string,
    public cancelled: boolean,
    public trying: boolean,
    public waiting: boolean,
    public comments: string,
    public invoice: Invoice|null,
    public discounts: Discount[],
    public activityAnalyticAccount: ActivityAnalyticAccount,
    public sale: SaleWithInvoice|null,
    public extraSales: SaleWithInvoice[],
    public fieldTexts: any,
    public fieldValues: any,
    public child: Child|null,
    public seats: InscriptionSeat[]
  ) {
  }

  public getPrice(): number|null {
    return calculateSoldPrice(this.getSales())
  }

  public getSales(extraOnly = false): Sale[] {
    const sales: Sale[] = []
    if (!extraOnly && this.sale) {
      sales.push(this.sale)
    }
    return sales.concat(this.extraSales)
  }

  public getFieldText(field: ActivityListField): string {
    const key = '' + field.field
    if (key in this.fieldTexts) {
      return this.fieldTexts[key]
    }
    return ''
  }

  public getFieldValue(field: ActivityListField): any {
    const key = '' + field.field
    if (key in this.fieldValues) {
      return this.fieldValues[key]
    }
    return ''
  }

  public isFieldValueVisible(field: ActivityListField) {
    // Pour certains champs, nous n'affichons que si la valeur est vraie ou fausse
    if ((!field.falseOnly) && (!field.trueOnly)) {
      return true
    }
    let value = false
    if (field.field in this.fieldValues) {
      value = !!this.fieldValues[field.field]
    }
    if (field.falseOnly && value) {
      return false
    }
    if (field.trueOnly && !value) {
      return false
    }
    return true
  }

  private getContactFieldValue(individual: Individual, fieldName: string): string {
    if (fieldName === 'email') {
      return individual.email
    }
    if (fieldName === 'proPhone') {
      return individual.proPhone
    }
    if (fieldName === 'cellPhone') {
      return individual.cellPhone
    }
    if (fieldName === 'mainPhone') {
      return individual.getMainPhone()
    }
    return ''
  }

  private getContactField(fieldName: string): string {
    const value = this.getContactFieldValue(this.individual, fieldName)
    if (value) {
      return value
    }
    if (this.child) {
      for (const parent of this.child.parents) {
        const value = this.getContactFieldValue(parent, fieldName)
        if (value) {
          return value
        }
      }
    }
    return ''
  }

  public getEmail(): string {
    return this.getContactField('email')
  }

  public getProPhone(): string {
    return this.getContactField('proPhone')
  }

  public getCellPhone(): string {
    return this.getContactField('cellPhone')
  }

  public getMainPhone(): string {
    return this.getContactField('mainPhone')
  }

  public getCellPhoneOrProPhone(): string {
    const cellPhone = this.getCellPhone()
    if (cellPhone) {
      return cellPhone
    } else {
      return this.getProPhone()
    }
  }
}

export function makeActivityInscription(jsonData: any = null): ActivityInscription {
  if (!jsonData) {
    jsonData = {}
  }
  const discounts = jsonData.discounts || []
  const extraSales = jsonData.extra_sales || []
  const seats = jsonData.seats || []
  return new ActivityInscription(
    jsonData.id || 0,
    makeIndividual(jsonData.individual),
    makeEntity(jsonData.entity),
    makeActivity(jsonData.activity),
    jsonData.price !== null ? +jsonData.price : 0,
    jsonData.created_on,
    jsonData.created_by || '',
    !!jsonData.cancelled,
    !!jsonData.trying,
    !!jsonData.waiting,
    jsonData.comments || '',
    jsonData.invoice ? makeInvoice(jsonData.invoice) : null,
    discounts.map((elt: any) => makeDiscount(elt)),
    makeActivityAnalyticAccount(jsonData.activity_analytic_account),
    makeSaleWithInvoice(jsonData.sale),
    extraSales.map((elt: any) => makeSaleWithInvoice(elt)),
    jsonData.field_texts || {},
    jsonData.field_values || {},
    jsonData.child ? makeChild(jsonData.child) : null,
    seats.map((elt: any) => makeInscriptionSeat(elt))
  )
}

export class ActivityListField {
  constructor(
    public id: number,
    public text: string,
    public fullText: string,
    public field: number,
    public trueOnly: boolean,
    public falseOnly: boolean,
    public canEdit: boolean,
    public applyTo: string[]
  ) {
  }
}

export function makeActivityListField(jsonData: any = null): ActivityListField {
  if (!jsonData) {
    jsonData = {}
  }
  return new ActivityListField(
    jsonData.id || 0,
    jsonData.text,
    jsonData.full_text,
    jsonData.field,
    jsonData.true_only,
    jsonData.false_only,
    jsonData.can_edit,
    jsonData.apply_to || []
  )
}

export enum AuthorizationAccessType {
  Allowed = 1,
  Forbidden = 2
}

export function getAccessType(accessType: AuthorizationAccessType): string {
  if (accessType === AuthorizationAccessType.Allowed) {
    return 'Liste blanche'
  } else if (accessType === AuthorizationAccessType.Forbidden) {
    return 'Liste noire'
  }
  return 'Inconnu'
}

export function getAccessTypeInfo(accessType: AuthorizationAccessType): string {
  if (accessType === AuthorizationAccessType.Allowed) {
    return 'L\'utilisateur ne peut voir que les activités pour lesquelles il a une autorisation explicite'
  } else if (accessType === AuthorizationAccessType.Forbidden) {
    return 'L\'utilisateur ne peut pas voir l\'activité'
  }
  return ''
}

export class ActivityAuthorization {
  constructor(
    public id: number,
    public accessType: AuthorizationAccessType,
    public user: StaffUser,
    public activity: Activity
  ) {
  }

  public getAccessType(): string {
    return getAccessType(this.accessType)
  }

  public getAccessTypeInfo(): string {
    return getAccessTypeInfo(this.accessType)
  }
}

export function makeActivityAuthorization(jsonData: any = null): ActivityAuthorization {
  if (!jsonData) {
    jsonData = {}
  }
  return new ActivityAuthorization(
    jsonData.id || 0,
    jsonData.access_type,
    makeStaffUser(jsonData.user),
    makeActivity(jsonData.activity)
  )
}
