import { createStore } from 'vuex'

import auth from './auth'
import certificates from './certificates'
import entity from './entity'
import loading from './loading'
import messages from './messages'
import options from '@/store/options'
import selection from './selection'
import settings from './settings'

const store = createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    certificates,
    entity,
    loading,
    messages,
    options,
    selection,
    settings,
  },
})

export default store
