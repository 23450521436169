import store from '@/store'
import { BackendApi, getErrorText } from '@/utils/http'
import { BackendInstance, FrontendInstance, makeBackendInstance, makeFrontendInstance } from '@/types/auth'
import axios from 'axios'

export async function loadFrontendInstance() {
  const appUrl = window.location.origin
  const backendApi = new BackendApi('post', '/api/backends/frontend/')
  try {
    const resp = await backendApi.callRouterApi({ 'app_url': appUrl, })
    const frontendInstance: FrontendInstance = makeFrontendInstance(resp.data)
    await store.dispatch('setFrontendInstance', frontendInstance)
  } catch (err) {
    await store.dispatch('addWarning', getErrorText(err))
  }
}

export function getRouterUrl(url: string): string {
  return process.env.VUE_APP_BACKEND + url
}

export function getBackends(email: string): Promise<BackendInstance[]> {
  return new Promise(
    (resolve, reject) => {
      axios({
        url: getRouterUrl('/api/backends/accounts/'),
        data: { email, },
        method: 'POST',
      }).then(async(resp: any) => {
        if (!resp.data.success) {
          reject(Error(resp.data.message))
        } else {
          const backends: BackendInstance[] = resp.data.backends.map(
            (jsonElt: any) => makeBackendInstance(jsonElt)
          ).filter(
            (elt: BackendInstance|null) => elt !== null
          )
          resolve(backends)
        }
      }).catch(err => {
        reject(err)
      })
    }
  )
}

export function checkBackend(url: string): Promise<string> {
  return new Promise(
    (resolve, reject) => {
      axios({
        url: getRouterUrl('/api/backends/check-portal-v2/'),
        data: { url, },
        method: 'POST',
      }).then(async(resp: any) => {
        let portalV2 = ''
        if (resp.data.family_portal_v2) {
          portalV2 = resp.data.family_portal_v2.url
        }
        resolve(portalV2)
      }).catch(err => {
        reject(err)
      })
    }
  )
}
