import { makePortalOptions, PortalOptions, PortalStructure } from '@/types/portal'

export const optionsModule = {
  state: {
    options: makePortalOptions(),
    structure: null,
  },
  getters: {
    options: (state: any) => {
      return state.options
    },
    structure: (state: any) => {
      return state.structure
    },
  },
  mutations: {
    setOptions(state: any, options: PortalOptions) {
      state.options = options
    },
    setStructure(state: any, structure: PortalStructure) {
      state.structure = structure
    },
  },
  actions: {
  },
  modules: {
  },
}

export default optionsModule
