import { encrypt, decrypt } from '@/utils/encrypt'
import { User } from '@/types/auth'

export function storeUser(user: User): void {
  const userAsString: string = JSON.stringify(user)
  window.localStorage.setItem('user', encrypt(userAsString))
}

export function loadUser(): User {
  const encryptedValue = window.localStorage.getItem('user')
  if (encryptedValue) {
    let value = ''
    try {
      value = decrypt(encryptedValue)
    } catch (exc1) {
      value = ''
    }
    if (value) {
      try {
        const user: User = JSON.parse(value)
        if (user.token.length > 0) {
          return user
        }
      } catch (exc2) {
        // ignore
      }
    }
  }
  // anonymous User
  return new User()
}

export const authModule = {
  state: {
    currentUser: loadUser(),
  },
  getters: {
    isAuthenticated: (state: any) => {
      return !!state.currentUser.token
    },
    userName: (state: any) => {
      const fullName = [state.currentUser.firstName, state.currentUser.lastName].filter(elt => !!elt).join(' ')
      return fullName || state.currentUser.email
    },
    authToken: (state: any) => {
      return state.currentUser.token
    },
    backendUrl: (state: any) => {
      return state.currentUser.backend ? state.currentUser.backend.url : ''
    },
    backendName: (state: any) => {
      return state.currentUser.backend ? state.currentUser.backend.name : ''
    },
    userEmail: (state: any) => {
      return state.currentUser.email
    },
  },
  mutations: {
    setUser(state: any, user: User) {
      state.currentUser = user
      storeUser(user)
    },
  },
  actions: {
    setUser(context: any, user: User) {
      context.commit('setUser', user)
    },
    resetUser(context: any) {
      context.commit('setUser', new User())
    },
  },
  modules: {
  },
}

export default authModule
