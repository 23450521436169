
export const loadingModule = {
  state: {
    loading: {},
  },
  getters: {
    loading: (state: any) => {
      return state.loading
    },
  },
  mutations: {
    startLoading(state: any, loadingName: string) {
      const loading = { ...state.loading, }
      loading[loadingName] = true
      state.loading = loading
    },
    endLoading(state: any, loadingName: string) {
      const loading = { ...state.loading, }
      loading[loadingName] = false
      state.loading = loading
    },
  },
  modules: {
  },
}

export default loadingModule
