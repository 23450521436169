import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: '',
        redirect: 'inscriptions',
        name: 'home',
      },
      {
        path: 'inscriptions',
        component: () => import('@/views/InscriptionTab.vue'),
      },
      {
        path: 'factures',
        component: () => import('@/views/InvoicesTab.vue'),
        name: 'invoices',
      },
      {
        path: 'docs',
        component: () => import('@/views/CertificatesTab.vue'),
        name: 'certificates',
      },
      {
        path: 'infos',
        component: () => import('@/views/AboutTab.vue'),
      }
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/login-callback',
    name: 'login-callback',
    component: () => import('@/views/FranceConnectLogin.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/View404.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to: any, from: any, next: any) => {
  document.title = 'millibase.net > Familles'
  if ((to.path !== '/login') && (to.path !== '/login-callback')) {
    if (store.getters.isAuthenticated) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})


export default router
