export const selectionModule = {
  state: {
    selectedDay: null,
    invoiceRefreshed: false,
    seancesRefreshed: false,
  },
  getters: {
    selectedDay: (state: any) => {
      return state.selectedDay
    },
    invoiceRefreshed: (state: any) => {
      return state.invoiceRefreshed
    },
    seancesRefreshed: (state: any) => {
      return state.seancesRefreshed
    },
  },
  mutations: {
    selectDay(state: any, selectedDay: any) {
      state.selectedDay = selectedDay
    },
    resetSelectedDay(state: any) {
      state.selectedDay = null
    },
    refreshInvoice(state: any) {
      state.invoiceRefreshed = true
    },
    refreshInvoiceDone(state: any) {
      state.invoiceRefreshed = false
    },
    reloadSeances(state: any) {
      state.seancesRefreshed = true
    },
    reloadSeancesDone(state: any) {
      state.seancesRefreshed = false
    },
  },
  actions: {
  },
  modules: {
  },
}

export default selectionModule
