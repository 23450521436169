import store from '@/store'
import axios from 'axios'
import router from '@/router'
import { User } from '@/types/auth'
import { loadUser } from '@/utils/auth'

export function getFullBackendUrl(url: string): string {
  return store.getters.backendUrl + url
}

export function gerRouterUrl(url: string): string {
  return process.env.VUE_APP_BACKEND + url
}

export function isAuthenticated(): boolean {
  return !!loadUser().token
}

function refreshNeeded(err: any): boolean {
  return (err.response && err.response.status === 401)
}

export function isAnonymous(err: any): boolean {
  return err.response && (err.response.status === 401)
}

function handleApiError(err: any, redirectToLogin: boolean, reject: any) {
  if (isAnonymous(err) && redirectToLogin) {
    store.dispatch('resetUser').then(
      () => {
        router.push({ name: 'login', }).then()
      }
    )
  } else {
    reject(err)
  }
}

function logoutUser() {
  store.dispatch('resetUser').then(
    () => {
      window.location.pathname = '/'
    }
  )
}

function handleRefreshTokenError(err: any, reject: any) {
  if (err.response && (err.response.status === 400 || err.response.status === 401)) {
    logoutUser()
  } else {
    reject(err)
  }
}

export class BackendApi {
  constructor(
    public method: string,
    public url: string
  ) {}

  public callApi(data: any = null, contentType: string = '', redirectToLogin: boolean = true): Promise<any> {
    const headers = {
      'Content-Type': contentType || 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + loadUser().token,
      'Accept-Language': 'fr',
    }
    const config: any = {
      url: getFullBackendUrl(this.url),
      method: this.method,
      headers: headers,
      data: data,
    }

    return new Promise(
      (resolve, reject) => {
        axios(config).then((resp: any) => {
          resolve(resp)
        }).catch((err: any) => {
          if (refreshNeeded(err)) {
            this.refreshToken().then(
              (refreshedUser: User) => {
                config.headers['Authorization'] = 'Bearer ' + refreshedUser.token
                axios(config).then((resp: any) => {
                  resolve(resp)
                }).catch((err2: any) => {
                  handleApiError(err2, redirectToLogin, reject)
                })
              }
            )
          } else {
            handleApiError(err, redirectToLogin, reject)
          }
        })
      }
    )
  }

  public async callRouterApi(data: any = null): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-Language': 'fr',
    }
    const config: any = {
      url: gerRouterUrl(this.url),
      method: this.method,
      headers: headers,
      data: data,
    }
    return new Promise(
      (resolve, reject) => {
        axios(config).then((resp: any) => {
          resolve(resp)
        }).catch((err: any) => {
          reject(err)
        })
      }
    )
  }

  public async refreshToken(): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-Language': 'fr',
    }
    const config: any = {
      url: getFullBackendUrl('/api/token/refresh/'),
      method: 'post',
      headers: headers,
      data: {
        refresh: loadUser().refresh,
      },
    }
    return new Promise(
      (resolve, reject) => {
        axios(config).then((resp: any) => {
          const user: User = loadUser()
          user.token = resp.data.access
          if (resp.data.refresh) {
            user.refresh = resp.data.refresh
          }
          store.dispatch('setUser', user).then(
            () => { resolve(user) }
          ).catch(
            (err2: any) => reject(err2)
          )
        }).catch((err: any) => {
          handleRefreshTokenError(err, reject)
        })
      }
    )
  }
}

export async function openDocument(docUrl: string, slug: string, doc: string = '', template: string = '') {
  const backendApi = new BackendApi('post', '/users/api/access-key/')
  const resp = await backendApi.callApi({ slug: slug, document: doc, template: template, })
  const key = resp.data.key
  docUrl = docUrl.replace('<key>', key)
  const fullUrl = getFullBackendUrl(docUrl)
  window.open(fullUrl, '_blank')
}

function getLineText(errors: any): string {
  let text = ''
  for (const elt in errors) {
    const item = errors[elt]
    if (Array.isArray(item)) {
      for (const line of item) {
        text += (line + '\n')
      }
    } else {
      text += (item + '\n')
    }
  }
  return text
}

export function getErrorText(err: any): string {
  if (err.response && err.response.status === 400) {
    let text = ''
    const data = err.response.data
    if (Array.isArray(data)) {
      for (const line of data) {
        text += getLineText(line)
      }
    } else {
      text += getLineText(data)
    }
    return text
  }
  return err.message
}

export function getHostName(backendName: string): string {
  let safeName = backendName.replace(/http.*:\/\//, '')
  safeName = safeName.split(':')[0]
  const elements = safeName.split('.')
  if (elements.length > 0) {
    // don't use first split if '127.0.0.1' or any other IP address
    if (isNaN(+elements[0])) {
      // first element is not a number. So the backend URL is not an IP address
      return elements[0]
    }
  }
  return safeName
}
