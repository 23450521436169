/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from 'moment'
import { Entity, makeEntity } from './people'
import { currency, dateToString } from '@/filters/texts'
import { makeSchoolYear, SchoolYear } from './schools'
import { isNumber } from '../utils/check'

export class InvoiceStatus {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export class SaleType {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makeSaleType(jsonData: any = null): SaleType {
  if (!jsonData) {
    jsonData = {}
  }
  return new SaleType(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class SaleGroup {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makeSaleGroup(jsonData: any = null): SaleGroup {
  if (!jsonData) {
    jsonData = {}
  }
  return new SaleGroup(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class Discount {
  constructor(
    public id: number,
    public amount: number,
    public percentage: number,
    public comments: string,
    public showPercentage: boolean
  ) {
  }

  public data(): any {
    return {
      id: this.id > 0 ? this.id : 0,
      amount: this.showPercentage ? 0 : this.amount,
      percentage: this.showPercentage ? this.percentage : 0,
      comments: this.comments,
    }
  }

  public clone(): Discount {
    return new Discount(
      this.id,
      this.amount,
      this.percentage,
      this.comments,
      this.showPercentage
    )
  }
}

export function makeDiscount(jsonData: any = null): Discount {
  if (!jsonData) {
    jsonData = {}
  }
  return new Discount(
    jsonData.id || 0,
    +jsonData.amount || 0,
    +jsonData.percentage || 0,
    jsonData.comments || '',
    (+jsonData.percentage || 0) !== 0
  )
}

export class AnalyticAccount {
  constructor(
    public id: number,
    public name: string,
    public label: string,
    public schoolYear: SchoolYear,
    public total: number | null
  ) {
  }

  public getKey(): string {
    return '' + this.id + ':' + this.schoolYear.id
  }

  public getLabel(): string {
    let label = ''
    if (this.id === 0) {
      label = 'Analytique manquant'
    } else {
      label = this.label || this.name
    }
    if (this.schoolYear.id) {
      label += ' ' + this.schoolYear.name
    }
    return label
  }
}

export function makeAnalyticAccount(jsonData: any = null): AnalyticAccount {
  if (!jsonData) {
    jsonData = {}
  }
  return new AnalyticAccount(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.label || '',
    makeSchoolYear(jsonData.school_year),
    isNumber(jsonData.total) ? jsonData.total : null
  )
}

export class GeneralAccount {
  constructor(
    public id: number,
    public name: string,
    public label: string,
    public isExpense: boolean,
    public isPayment: boolean
  ) {
  }

  public getLabel(): string {
    if (this.id === 0) {
      return 'Code manquant'
    } else {
      return this.label || this.name
    }
  }

  public getFullLabel(): string {
    if (this.id === 0) {
      return 'Code manquant'
    } else {
      return this.name + (this.label ? (' - ' + this.label) : '')
    }
  }
}

export function makeGeneralAccount(jsonData: any = null): GeneralAccount {
  if (!jsonData) {
    jsonData = {}
  }
  return new GeneralAccount(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.label || '',
    !!jsonData.is_expense,
    !!jsonData.is_payment
  )
}

export class AnalyticItem {
  constructor(
    public analyticAccount: AnalyticAccount,
    public generalAccount: GeneralAccount,
    public schoolYear: SchoolYear
  ) {
  }

  public getKey(): string {
    return '' + this.analyticAccount.id + ':' + this.generalAccount.id + ':' + this.schoolYear.id
  }

  public asText(): string {
    const schoolYear = this.schoolYear.name
    const general = this.getGeneralAccountLabel()
    const analytic = this.getAnalyticAccountLabel()
    return schoolYear + ' - ' + general + ' - ' + analytic
  }

  public getAnalyticAccountLabel(): string {
    return this.analyticAccount.getLabel()
  }

  public getGeneralAccountLabel(): string {
    return this.generalAccount.label || this.generalAccount.name
  }
}

export function makeAnalyticItem(jsonData: any = null): AnalyticItem {
  if (!jsonData) {
    jsonData = {}
  }
  return new AnalyticItem(
    makeAnalyticAccount(jsonData.analytic_account),
    makeGeneralAccount(jsonData.general_account),
    makeSchoolYear(jsonData.school_year)
  )
}

export class Sale {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public basePrice: number,
    public price: number,
    public label: string,
    public saleType: SaleType,
    public group: SaleGroup,
    public trying: boolean,
    public cancellation: boolean,
    public cancellationConfirmed: boolean,
    public refunded: boolean,
    public ignored: boolean,
    public discounts: Discount[],
    public analyticAccount: AnalyticAccount|null
  ) {
  }

  public creationTime() {
    return moment(this.createdOn).format('HH:mm')
  }
}

export function makeSale(jsonData: any = null): Sale {
  if (!jsonData) {
    jsonData = {}
  }
  const discounts = jsonData.discounts || []
  return new Sale(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.base_price || 0,
    +jsonData.price || 0,
    jsonData.label || '',
    makeSaleType(jsonData.sale_type),
    makeSaleGroup(jsonData.group),
    !!jsonData.trying,
    !!jsonData.cancellation,
    !!jsonData.cancellation_confirmed,
    !!jsonData.refunded,
    !!jsonData.ignored,
    discounts.map((elt: any) => makeDiscount(elt)),
    jsonData.analytic_account ? makeAnalyticAccount(jsonData.analytic_account) : null
  )
}

export class SaleWithInvoice extends Sale {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public basePrice: number,
    public price: number,
    public label: string,
    public saleType: SaleType,
    public group: SaleGroup,
    public trying: boolean,
    public cancellation: boolean,
    public cancellationConfirmed: boolean,
    public refunded: boolean,
    public ignored: boolean,
    public discounts: Discount[],
    public analyticAccount: AnalyticAccount|null,
    public invoice: Invoice|null
  ) {
    super(
      id, createdOn, createdBy, basePrice, price, label, saleType, group, trying,
      cancellation, cancellationConfirmed, refunded, ignored, discounts, analyticAccount
    )
  }
}

export function makeSaleWithInvoice(jsonData: any = null): SaleWithInvoice {
  if (!jsonData) {
    jsonData = {}
  }
  if (!jsonData) {
    jsonData = {}
  }
  const discounts = jsonData.discounts || []
  return new SaleWithInvoice(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.base_price || 0,
    +jsonData.price || 0,
    jsonData.label || '',
    makeSaleType(jsonData.sale_type),
    makeSaleGroup(jsonData.group),
    !!jsonData.trying,
    !!jsonData.cancellation,
    !!jsonData.cancellation_confirmed,
    !!jsonData.refunded,
    !!jsonData.ignored,
    discounts.map((elt: any) => makeDiscount(elt)),
    jsonData.analytic_account ? makeAnalyticAccount(jsonData.analytic_account) : null,
    jsonData.invoice ? makeInvoice(jsonData.invoice) : null
  )
}

export function calculatePrice(basePrice: number, discounts: Discount[]): number {
  let price = basePrice
  for (const discount of discounts) {
    if (discount.amount) {
      price -= discount.amount
    } else {
      price -= discount.percentage * basePrice / 100
    }
  }
  return Math.round(price * 100) / 100
}

export function calculateSoldPrice(sales: Sale[]): number {
  let price = 0
  for (const sale of sales) {
    price += sale.basePrice
    for (const discount of sale.discounts) {
      if (discount.amount) {
        price -= discount.amount
      } else {
        price -= discount.percentage * sale.price / 100
      }
    }
  }
  return Math.round(price * 100) / 100
}

export class PaymentMode {
  constructor(
    public id: number,
    public name: string,
    public bank: boolean,
    public order: number,
    public isNumberRequired: boolean,
    public hasDeposit: boolean
  ) {
  }
}

export function makePaymentMode(jsonData: any = null): PaymentMode {
  if (!jsonData) {
    jsonData = {}
  }
  return new PaymentMode(
    jsonData.id || 0,
    jsonData.name || '',
    !!jsonData.bank,
    jsonData.order || 0,
    !!jsonData.bank && !!jsonData.is_number_required,
    !!jsonData.has_deposit
  )
}

export class Refund {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public comments: string,
    public refundMode: PaymentMode,
    public bankName: string,
    public bankNumber: string,
    public amount: number
  ) {
  }

  public creationTime() {
    return moment(this.createdOn).format('HH:mm')
  }
}

export function makeRefund(jsonData: any = null): Refund {
  if (!jsonData) {
    jsonData = {}
  }
  return new Refund(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    jsonData.comments || '',
    makePaymentMode(jsonData.refund_mode),
    jsonData.bank_name || '',
    jsonData.bank_number || '',
    +jsonData.amount || 0
  )
}

export class AnalyticDetail {
  constructor(
    public analyticAccount: AnalyticAccount,
    public generalAccount: GeneralAccount,
    public schoolYear: SchoolYear,
    public amount: number, // Montant à facturer
    public noInvoiceAmount: number = 0, // Montant non facturé
    public toBePaidAmount: number = 0, // Montant à payer
    public paymentContributions: number = 0,
    public creditContributions: number = 0,
    public cancellationCredits: number = 0,
    public overpaidCredits: number = 0,
    public remainingCredits: number = 0,
    public refundCredits: number = 0
  ) {
  }

  public getKey(): string {
    return [this.analyticAccount.id, this.generalAccount.id, this.schoolYear.id].join(':')
  }

  public getLabel(): string {
    return this.analyticAccount.getLabel() + ' ' + this.schoolYear.name
  }

  public getFullLabel(): string {
    return this.generalAccount.name + ' ' + this.analyticAccount.getLabel() + ' ' + this.schoolYear.name
  }

  public getPaid() {
    return this.paymentContributions + this.creditContributions
  }

  public getToBePaid() {
    return this.toBePaidAmount
  }

  public getMoneyPayments(): number {
    return this.paymentContributions + this.overpaidCredits
  }

  public getCreditOrigin(): number {
    return this.cancellationCredits + this.overpaidCredits
  }

  public getAvailable(): number {
    return this.remainingCredits
  }

  public getRefund(): number {
    return this.refundCredits
  }

  public clone(): AnalyticDetail {
    return new AnalyticDetail(
      this.analyticAccount,
      this.generalAccount,
      this.schoolYear,
      this.amount
    )
  }
}

export function makeAnalyticDetail(jsonData: any = null): AnalyticDetail {
  if (!jsonData) {
    jsonData = {}
  }
  return new AnalyticDetail(
    makeAnalyticAccount(jsonData.analytic_account),
    makeGeneralAccount(jsonData.general_account),
    makeSchoolYear(jsonData.school_year),
    +(jsonData.amount || 0),
    +(jsonData.no_invoice_amount || 0),
    +(jsonData.to_be_paid_amount || 0),
    +(jsonData.payment_contributions || 0),
    +(jsonData.credit_contributions || 0),
    +(jsonData.cancellation_credits || 0),
    +(jsonData.overpaid_credits || 0),
    +(jsonData.remaining_credits || 0),
    +(jsonData.refund_credits || 0)
  )
}

export class Credit {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public amount: number,
    public remainingAmount: number,
    public source: string,
    public refund: Refund|null,
    public entity: Entity|null,
    public invoices: Invoice[],
    public comments: string,
    public ignored: boolean,
    public analytics: AnalyticDetail[],
    public fromAnalytics: AnalyticDetail[]
  ) {
  }

  public creationTime() {
    return moment(this.createdOn).format('HH:mm')
  }

  public isAvailable(): boolean {
    return this.remainingAmount > 0
  }
}

export function makeCredit(jsonData: any = null): Credit {
  if (!jsonData) {
    jsonData = {}
  }
  let analytics = []
  if (jsonData.analytics) {
    analytics = jsonData.analytics.map((elt: any) => makeAnalyticDetail(elt))
  }
  let fromAnalytics = []
  if (jsonData.from_analytics) {
    fromAnalytics = jsonData.from_analytics.map((elt: any) => makeAnalyticDetail(elt))
  }
  let invoices = []
  if (jsonData.invoices) {
    invoices = jsonData.invoices.map((elt: any) => makeInvoice(elt))
  }
  return new Credit(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.amount || 0,
    +jsonData.remaining_amount || 0,
    jsonData.source || '',
    jsonData.refund ? makeRefund(jsonData.refund) : null,
    makeEntity(jsonData.entity),
    invoices,
    jsonData.comments || '',
    !!jsonData.ignored,
    analytics,
    fromAnalytics
  )
}

export class Deposit {
  constructor(
    public id: number,
    public number: string,
    public depositOn: Date,
    public comments: string
  ) {
  }
}

export function makeDeposit(jsonData: any = null): Deposit {
  if (!jsonData) {
    jsonData = {}
  }
  return new Deposit(
    jsonData.id || 0,
    jsonData.number || '',
    jsonData.deposit_on,
    jsonData.comments || ''
  )
}

export class Payment {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public amount: number,
    public comments: string,
    public paymentMode: PaymentMode,
    public bankName: string,
    public bankNumber: string,
    public paymentDate: Date|null,
    public verificationDate: Date,
    public operationCode: string,
    public credits: Credit[],
    public invoices: Invoice[],
    public isVerified: boolean,
    public entity: Entity,
    public deposit: Deposit,
    public analytics: AnalyticDetail[]
  ) {
  }

  public paidOn(): Date {
    return this.paymentDate ? this.paymentDate : this.createdOn
  }

  public delayedPayement(): boolean {
    return this.paymentDate !== this.createdOn
  }
}

export function makePayment(jsonData: any = null): Payment {
  if (!jsonData) {
    jsonData = {}
  }
  const credits = jsonData.credits || []
  let analytics = []
  if (jsonData.analytics) {
    analytics = jsonData.analytics.map((elt: any) => makeAnalyticDetail(elt))
  }
  let invoices = []
  if (jsonData.invoices) {
    invoices = jsonData.invoices.map((elt: any) => makeInvoice(elt))
  }
  return new Payment(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.amount || 0,
    jsonData.comments || '',
    makePaymentMode(jsonData.payment_mode),
    jsonData.bank_name || '',
    jsonData.bank_number || '',
    jsonData.payment_date || null,
    jsonData.verification_date,
    jsonData.operation_code || '',
    credits.map((elt: any) => makeCredit(elt)),
    invoices,
    !!jsonData.is_verified,
    makeEntity(jsonData.entity),
    makeDeposit(jsonData.deposit),
    analytics
  )
}

export function sumPayments(payments: Payment[]) {
  return payments.reduce((sum, payment) => sum + (+payment.amount), 0)
}

export class Contribution {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public amount: number,
    public payment: Payment|null,
    public credit: Credit|null
  ) {
  }
}

export function makeContribution(jsonData: any = null): Contribution {
  if (!jsonData) {
    jsonData = {}
  }
  return new Contribution(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.amount || 0,
    jsonData.payment ? makePayment(jsonData.payment) : null,
    jsonData.credit ? makeCredit(jsonData.credit) : null
  )
}

export class Cancellation {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public amount: number,
    public sale: Sale,
    public credit: Credit
  ) {
  }
}

export function makeCancellation(jsonData: any = null): Cancellation {
  if (!jsonData) {
    jsonData = {}
  }
  return new Cancellation(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.amount || 0,
    makeSale(jsonData.sale),
    makeCredit(jsonData.credit)
  )
}

export class Invoice {
  constructor(
    public id: number,
    public invoiceNumber: number,
    public createdOn: Date,
    public createdBy: string,
    public status: string,
    public totalPrice: number,
    public paidPrice: number,
    public isSent: boolean,
    public isPaid: boolean,
    public isVerified: boolean,
    public isCancelled: boolean,
    public entity: Entity|null,
    public sales: Sale[],
    public payments: Payment[],
    public credits: Credit[],
    public webPaymentUrl: string,
    public fakeUuid: string,
    public contributions: Contribution[],
    public cancellations: Cancellation[],
    public analytics: AnalyticDetail[],
    public frozen: number
  ) {
  }

  public toBePaidPrice(): number {
    if (this.frozen) {
      return 0
    }
    return this.totalPrice - this.paidPrice
  }

  public totalCredits() {
    let totalCredits = 0
    for (const cancellation of this.cancellations) {
      totalCredits += cancellation.credit.amount
    }
    return totalCredits
  }

  public totalPayments() {
    let total = 0
    for (const contribution of this.contributions) {
      total += contribution.amount
    }
    return total
  }

  public creationTime() {
    return moment(this.createdOn).format('HH:mm')
  }

  public getValidWebPayementUrl(uuid: string): string {
    return this.webPaymentUrl.replace(this.fakeUuid, uuid)
  }

  public getLabel() {
    let label = this.status
    const toBePaid = this.toBePaidPrice()
    if (toBePaid) {
      label += ' ' + currency(Math.abs(toBePaid))
    }
    return label
  }

  public getName() {
    return 'N°' + this.invoiceNumber + ' À Payer ' + currency(Math.abs(this.toBePaidPrice()))
  }
}

export function makeInvoice(jsonData: any = null): Invoice {
  if (!jsonData) {
    jsonData = {}
  }
  const entity = jsonData.entity || null
  const sales = jsonData.sales || []
  const payments = jsonData.payments || []
  const credits = jsonData.credits || []
  let analytics = []
  if (jsonData.analytics) {
    analytics = jsonData.analytics.map((elt: any) => makeAnalyticDetail(elt))
  }
  const contributions = jsonData.contributions || []
  const cancellations = jsonData.cancellations || []
  return new Invoice(
    jsonData.id || 0,
    jsonData.number || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    jsonData.status || '',
    +jsonData.total_price || 0,
    +jsonData.paid_price || 0,
    !!jsonData.is_sent,
    !!jsonData.is_paid,
    !!jsonData.is_verified,
    !!jsonData.is_cancelled,
    entity !== null ? makeEntity(entity) : null,
    sales.map((elt: any) => makeSale(elt)),
    payments.map((elt: any) => makePayment(elt)),
    credits.map((elt: any) => makeCredit(elt)),
    jsonData.web_payment_url || '',
    jsonData.fake_uuid || '',
    contributions.map((elt: any) => makeContribution(elt)),
    cancellations.map((elt: any) => makeCancellation(elt)),
    analytics,
    jsonData.frozen || 0
  )
}

export class PaymentDate {
  constructor(
    public value: string,
    public label: string,
    public date: Date|null = null
  ) {
  }

  public getLabel(): string {
    if (this.date) {
      return dateToString(this.date, 'DD MMMM YYYY')
    } else {
      return this.label
    }
  }
}

export function makePaymentDate(jsonData: any = null): PaymentDate {
  if (!jsonData) {
    jsonData = {}
  }
  return new PaymentDate(
    jsonData.value || '',
    jsonData.label || '',
    jsonData.date || null
  )
}
